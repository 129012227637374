import { errorCallback, successCallback } from '@/helpers/validation';
import Touch from './touch';

Touch.prototype.staffGetEnquiryExpirationTime = async function staffGetEnquiryExpirationTime() {
  return (await this.authenticatedPost('staff/GetEnquiryExpirationTime')).data;
};

Touch.prototype.staffUpdateEnquiryExpirationTime = async function staffUpdateEnquiryExpirationTime(
  newTime,
) {
  return this.authenticatedPost('staff/UpdateEnquiryExpirationTime', {
    EnquiryOverdueHours: newTime,
  });
};

Touch.prototype.staffUpdateAlternativePriceData = async function staffUpdateAlternativePriceData(
  customerId,
  datasetId,
  anomalies,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateAlternativePriceData', {
      CustomerId: customerId,
      DatasetId: datasetId,
      Anomalies: anomalies,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.staffGetFinancialGroups = async function staffGetFinancialGroups(
  customerId,
  datasetId,
) {
  return this.authenticatedPost('staff/GetFinancialGroups', {
    CustomerId: customerId,
    DatasetId: datasetId,
  });
};

Touch.prototype.staffGetAlternativePriceData = async function staffGetAlternativePriceData(
  customerId,
  datasetId,
  groupId,
  description,
  offset,
  limit,
) {
  return this.authenticatedPost('staff/GetAlternativePriceData', {
    CustomerId: customerId,
    DatasetId: datasetId,
    GroupId: groupId,
    Description: description,
    Offset: offset,
    Limit: limit,
  });
};

Touch.prototype.staffUpdateProductSelectionGroups =
  async function staffUpdateProductSelectionGroups(groupList) {
    return this.authenticatedPost('staff/UpdateProductSelectionGroups', {
      ProductSelection: groupList,
    });
  };

Touch.prototype.staffGetLatestExchangeRates = async function staffGetLatestExchangeRates() {
  return this.authenticatedPost('staff/GetLatestExchangeRates');
};

Touch.prototype.staffUpdateCurrency = async function staffUpdateCurrency(currencyList) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateCurrency', {
      Currencies: currencyList,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.staffUpdateProductSelectionTypes = async function staffUpdateProductSelectionTypes(
  groupList,
) {
  return this.authenticatedPost('staff/UpdateProductSelectionTypes', {
    ProductSelection: groupList,
  });
};

Touch.prototype.staffAssignEnquiry = async function staffAssignEnquiry(contractId, userIds) {
  return this.parseResponse(
    await this.authenticatedPost('staff/AssignEnquiry', {
      ContractId: Number(contractId),
      CustomerIds: userIds,
    }),
  );
};

Touch.prototype.staffUpdateOrganisationDetails = async function staffUpdateOrganisationDetails(
  name,
  email,
  phone,
  web,
  file,
) {
  return this.authenticatedPost('staff/updateOrganisationDetails', {
    Name: name,
    Email: email,
    Phone: phone,
    Web: web,
    Styling: {
      Logo: file,
    },
  });
};

Touch.prototype.staffListCustomers = async function staffListCustomers(
  limit,
  offset,
  searchName,
  contractId,
  dealerNetworkPartnerOnly = false,
  CustomerStatus,
  generalLicenseStatus,
  BranchCompanyId
) {
  const response = await this.authenticatedPost('staff/ListCustomers', {
    limit,
    offset,
    searchName,
    GetTotal: true,
    ContractIDForDistance: contractId,
    DealerNetworkPartnerOnly: dealerNetworkPartnerOnly,
    CustomerStatus,
    generalLicenseStatus,
    BranchCompanyId
  });
  const customers = response.data.customers.map((customer) => ({
    ...customer,
    url: `/customer/${customer.id}/details`,
  }));
  return {
    customers,
    total_items: response.data.total,
  };
};

Touch.prototype.staffGetOrganisationCustomerId = async function staffGetOrganisationCustomerId() {
  const data = await this.parseResponse(
    await this.authenticatedPost('staff/GetOrganisationCustomerId'),
    errorCallback
  )
  return data?.customerId
};

Touch.prototype.staffList = async function staffList() {
  const response = await this.authenticatedPost('staff/List');
  return response.data.map((staffMember) => ({
    ...staffMember,
    url: `/staff/${staffMember.id}`,
  }));
};

Touch.prototype.staffGetTaxRates = async function staffGetTaxRates() {
  const { data } = await this.authenticatedPost('staff/GetTaxRates');
  return data;
};

Touch.prototype.staffUpdateTaxRate = async function staffUpdateTaxRate(TaxRate) {
  const { data } = await this.authenticatedPost('staff/UpdateTaxRate', {
    Id: Number(TaxRate.id),
    Name: TaxRate.name,
    Value: Number(TaxRate.value),
    IsDefault: TaxRate.isDefault,
  });
  return data;
};

Touch.prototype.staffAddTaxRate = async function staffAddTaxRate(TaxRate) {
  return this.parseResponse(
    await this.authenticatedPost('staff/AddTaxRate', {
      Name: TaxRate.name,
      Value: Number(TaxRate.value),
      IsDefault: TaxRate.isDefault,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.staffDeleteTaxRate = async function staffDeleteTaxRate(id) {
  return this.parseResponse(
    await this.authenticatedPost(
      'staff/DeleteTaxRate',
      {
        Id: Number(id),
      },
      errorCallback,
      () => successCallback('Delete'),
    ),
  );
};

Touch.prototype.staffGetStaffContact = async function staffGetStaffContact(ContactId) {
  const { data } = await this.authenticatedPost('staff/GetStaffContact', {
    Id: Number(ContactId),
  });
  return data;
};

Touch.prototype.staffEditStaffContact = async function staffEditStaffContact(Contact) {
  const { data } = await this.authenticatedPost('staff/EditStaffContact', Contact);
  return data;
};

Touch.prototype.staffCreateStaffContact = async function staffCreateStaffContact(Contact) {
  const { data } = await this.authenticatedPost('staff/CreateStaffContact', Contact);
  return data;
};

Touch.prototype.staffGetDiscountList = async function staffGetDiscountList(datasetId) {
  const { data } = await this.authenticatedPost('staff/GetDiscountList', {
    Id: datasetId,
  });
  return data;
};

Touch.prototype.staffUpdateDiscountCategories = async function staffUpdateDiscountCategories(
  discounts,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateDiscountCategories', {
      DiscountCategories: discounts,
    }),
    errorCallback,
    () => successCallback(discounts[0].Delete ? 'Delete' : 'Save')
  )
};

Touch.prototype.staffListDiscountCategories = async function staffListDiscountCategories() {
  const { data } = await this.authenticatedPost('staff/ListDiscountCategories');
  return data;
};

Touch.prototype.staffGetCustomerDiscounts = async function staffGetCustomerDiscounts(
  customerId,
  datasetId,
) {
  const { data } = await this.authenticatedPost('staff/GetCustomerDiscounts', {
    CustomerId: Number(customerId),
    DatasetId: datasetId,
  });
  return data.discounts;
};

Touch.prototype.staffUpdateDiscounts = async function staffUpdateDiscounts(
  customerId,
  datasetId,
  discounts,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateDiscounts', {
      CustomerId: Number(customerId),
      DatasetId: datasetId,
      Discounts: discounts.map((discount) => ({
        Id: discount.id,
        Value: Number(discount.value),
      }))
    }),
    errorCallback,
    () => successCallback('Save')
  )
};

Touch.prototype.staffGetBasePrices = async function staffGetBasePrices(
  datasetId,
  limit,
  offset,
  groupId,
  description = null,
) {
  const { data } = await this.authenticatedPost('staff/GetBasePrices', {
    limit,
    offset,
    datasetId,
    groupId,
    description,
  });
  return data;
};

Touch.prototype.staffGetFinancialOverrides = async function staffGetFinancialOverrides(
  datasetId,
  financialId,
  limit,
  offset,
) {
  const { data } = await this.authenticatedPost('staff/GetFinancialOverrides', {
    limit,
    offset,
    datasetId,
    financialId,
  });
  return data;
};

Touch.prototype.staffUpdateBasePrices = async function staffUpdateBasePrices(
  datasetId,
  financials,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateBasePrices', {
      DatasetId: datasetId,
      Financials: financials,
    }),
    errorCallback,
    successCallback('Save')
  )
};

Touch.prototype.staffUpdateBasePriceGrid = async function staffUpdateBasePriceGrid(
  datasetId,
  financialId,
  financialGridConfigurationId,
  cells,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateBasePriceGrid', {
      DatasetId: datasetId,
      Id: financialId,
      FinancialGridConfigurationId: financialGridConfigurationId,
      Cells: cells,
    }),
    () => {
      throw new Error('Error');
    },
  );
};

Touch.prototype.staffUpdateBasePriceList = async function staffUpdateBasePriceList(
  datasetId,
  financialId,
  financialGridConfigurationId,
  cells,
) {
  return this.authenticatedPost('staff/UpdateBasePriceList', {
    DatasetId: datasetId,
    Id: financialId,
    FinancialGridConfigurationId: financialGridConfigurationId,
    Cells: cells,
  });
};

Touch.prototype.staffGetBasePriceList = async function staffGetBasePriceList(
  datasetId,
  financialId,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/GetBasePriceList', {
      DatasetId: datasetId,
      Id: financialId,
    }),
    () => {
      throw new Error('Error');
    },
  );
};

Touch.prototype.UpdateBaseValueWidthByHeight = async function UpdateBaseValueWidthByHeight(
  datasetId,
  financialId,
  cells,
) {
  return this.authenticatedPost('staff/UpdateBaseValueWidthByHeight', {
    DatasetId: datasetId,
    Id: financialId,
    Data: cells,
  });
};

Touch.prototype.UpdateValueWidthByHeightAnomolies =
  async function UpdateValueWidthByHeightAnomolies(datasetId, financialId, CustomerId, cells) {
    return this.authenticatedPost('staff/UpdateValueWidthByHeightAnomolies', {
      DatasetId: datasetId,
      Id: financialId,
      CustomerId,
      Data: cells,
    });
  };

Touch.prototype.staffGetBasePriceGrid = async function staffGetBasePriceGrid(
  datasetId,
  financialId,
) {
  const { data } = await this.authenticatedPost('staff/GetBasePriceGrid', {
    DatasetId: datasetId,
    Id: financialId,
  });
  return data;
};

Touch.prototype.staffUpdateFinancialGroups = async function staffUpdateFinancialGroups(
  datasetId,
  financialGroups,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateFinancialGroups', {
      DatasetId: datasetId,
      FinancialGroups: financialGroups,
    }),
    errorCallback,
    () => successCallback('Save'),
  )
};

Touch.prototype.staffMoveFinancialItems = async function staffMoveFinancialItems(
  datasetId,
  groupId,
  financialItemIds,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/MoveFinancialItems', {
      DatasetId: datasetId,
      GroupId: groupId,
      ItemIds: financialItemIds,
    }),
    errorCallback,
    () => successCallback('Save'),
  )
};

Touch.prototype.staffListStaff = async function staffListStaff(limit, offset, branchCompanyId) {
  const { data } = await this.authenticatedPost('staff/ListStaff', {
    limit,
    offset,
    GetTotal: true,
    branchCompanyId,
  });
  return data;
};

Touch.prototype.staffGetThisListAnomaly = async function staffGetThisListAnomaly(
  id,
  customerId,
  datasetId,
) {
  return (
    await this.authenticatedPost('staff/GetThisListAnomaly', {
      Id: id,
      CustomerId: customerId,
      DatasetId: datasetId,
    })
  ).data;
};

Touch.prototype.staffUpdateThisListAnomaly = async function staffUpdateThisListAnomaly(
  datasetId,
  id,
  gridId,
  overrideCells,
  customerId,
) {
  return (
    await this.authenticatedPost('staff/UpdateThisListAnomaly', {
      Id: id,
      CustomerId: customerId,
      OverrideCells: overrideCells,
      DatasetId: datasetId,
      FinancialGridConfigurationId: gridId,
    })
  ).data;
};

Touch.prototype.staffGetDeliveryOptions = async function staffGetDeliveryOptions(
  Name,
  Offset,
  Limit,
  DeliveryDays,
) {
  const { data } = await this.authenticatedPost('staff/GetDeliveryOptions', {
    Name,
    Offset,
    Limit,
    DeliveryDays,
  });
  return data;
};

Touch.prototype.staffUpdateDeliveryOptions = async function stafUpdateDeliveryOptions(
  deliveryOption,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateDeliveryOptions', {
      DeliveryOptionDetails: deliveryOption,
    }),
    errorCallback,
    (r) => successCallback('Save', r.data),
  );
};

Touch.prototype.staffGetPostCodes = async function staffGetPostCodes(Prefix, Offset, Limit) {
  return this.parseResponse(
    await this.authenticatedPost('staff/GetPostcodes', {
      Prefix,
      Offset,
      Limit,
    }),
  );
};

Touch.prototype.staffUpdatePostcodes = async function staffUpdatePostcodes(PostCode) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdatePostcodes', {
      PostcodeDetails: PostCode,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.staffGetOptionHeadings = async function GetOptionHeadings() {
  const { headings } = await this.parseResponse(
    await this.authenticatedGet('staff/GetOptionHeadings'),
  );
  return headings;
};

Touch.prototype.staffGetOptionItems = async function staffGetOptionItems(headingType) {
  const { items } = await this.parseResponse(
    await this.authenticatedGet(`staff/GetOptionItems?headingType=${headingType}`),
  );
  return items;
};

Touch.prototype.staffUpdateOptionItems = async function staffUpdateOptionItems(
  DatasetId,
  OptionItems,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateOptionItems', {
      DatasetId,
      items: OptionItems,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.staffGetOptionGroups = async function staffGetOptionGroups() {
  return this.parseResponse(await this.authenticatedGet('staff/GetOptionGroups'));
};

Touch.prototype.staffGetFenestrationOptionGroups = async function staffGetFenestrationOptionGroups(datasetId) {
  return this.parseResponse(await this.authenticatedGet(`staff/GetFenestrationOptionGroups?DatasetId=${datasetId}`));
};

Touch.prototype.staffGetOptionHeadingsByGroup = async function staffGetOptionHeadingsByGroup(
  groupId
) {
  return this.parseResponse(
    await this.authenticatedGet(`staff/GetOptionHeadingsByGroup?groupId=${groupId}`),
  );
};

Touch.prototype.staffGetFenestrationOptionHeadingsForGroup = async function staffGetFenestrationOptionHeadingsForGroup(
  datasetId,
  groupId,
) {
  return this.parseResponse(
    await this.authenticatedGet(`staff/GetFenestrationOptionHeadingsForGroup?DatasetId=${datasetId}&OptionGroupId=${groupId}`),
  );
};

Touch.prototype.staffGetCustomerOptionItems = async function staffGetCustomerOptionItems(
  headingType,
  customerId,
) {
  return this.parseResponse(
    await this.authenticatedGet(
      `staff/GetCustomerOptionItems?headingType=${headingType}&customerId=${customerId}`,
    ),
  );
};

Touch.prototype.staffGetFenestrationOptionItemsForHeading = async function staffGetFenestrationOptionItemsForHeading(
  datasetId,
  headingType,
) {
  return this.parseResponse(
    await this.authenticatedGet(
      `staff/GetFenestrationOptionItemsForHeading?DatasetId=${datasetId}&OptionHeadingId=${headingType}`,
    ),
  );
};

Touch.prototype.UpdateCustomerOptionItems = async function staffUpdateCustomerOptionItems(
  DatasetId,
  Items,
  CustomerId,
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateCustomerOptionItems', {
      CustomerId,
      DatasetId,
      Items,
    }),
    errorCallback,
    () => successCallback('Save'),
  );
};

Touch.prototype.UpdateOptionConfigurations = async function UpdateOptionConfigurations(
  DatasetId,
  OptionGroups,
  OptionHeadings,
  OptionItems
) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateOptionConfiguration', {
      DatasetId,
      OptionGroups,
      OptionHeadings,
      OptionItems
    }),
    errorCallback,
    () => successCallback('Save')
  );
};

Touch.prototype.GetCustomerCount = async function GetCustomerCount() {
  return this.parseResponse(await this.authenticatedPost('staff/GetCustomerCount'));
};

Touch.prototype.updateAccountSettings = async function updateAccountSettings(accountSettings) {
  return this.parseResponse(await this.authenticatedPost('staff/UpdateAccountSettings', {
    EmailOff: accountSettings.emailOff,
    showFitting: accountSettings.showFitting,
  }), errorCallback, () => successCallback('Save'));
};

Touch.prototype.ListDiscountVouchers = async function ListDiscountVouchers(query) {
  return this.parseResponse(await this.authenticatedPost('staff/ListDiscountVouchers', query));
};

Touch.prototype.CreateDiscountVoucher = async function CreateDiscountVoucher(voucher) {
  return this.parseResponse(await this.authenticatedPost('staff/CreateDiscountVoucher', voucher), errorCallback, (r) => successCallback('Save', r));
};

Touch.prototype.UpdateDiscountVoucher = async function UpdateDiscountVoucher(voucher) {
  return this.parseResponse(await this.authenticatedPost('staff/UpdateDiscountVoucher', voucher), errorCallback, (r) => successCallback('Save', r));
};

Touch.prototype.DeleteDiscountVoucher = async function DeleteDiscountVoucher(id) {
  return this.parseResponse(await this.authenticatedPost('staff/DeleteDiscountVoucher', { id }), errorCallback, () => successCallback('Delete'));
};

Touch.prototype.GetBrandedFabricatorLimit = async function GetBrandedFabricatorLimit() {
  return this.parseResponse(await this.authenticatedPost('staff/GetBrandedFabricatorLimit'), (e) => e, (r) => r);
};

Touch.prototype.ListCustomerBrandedDetails = async function ListCustomerBrandedDetails(query) {
  return this.parseResponse(await this.authenticatedPost('staff/ListCustomerBrandedDetails', query));
};

Touch.prototype.GetFinancialCategories = async function GetFinancialCategories() {
  return this.parseResponse(await this.authenticatedPost('staff/GetFinancialCategories'));
};

Touch.prototype.UpdateFinancialCategories = async function UpdateFinancialCategories(payload) {
  return this.parseResponse(
    await this.authenticatedPost('staff/UpdateFinancialCategories', {
      FinancialCategoryDetails: payload
    }),
    errorCallback, (r) => successCallback('Save', r)
  );
};
