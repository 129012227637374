<template>
  <div>
    <template v-if="single_target_question">
      <single-target-instant-selection-question
        v-if="single_spec.instantSelectionOptionItemId && single_spec.instantSelectionOptionItemId !== '00000000-0000-0000-0000-000000000000'"
        :loading="loading"
        :spec="single_spec"
        @change="singleChange($event)"
      >
      </single-target-instant-selection-question>
      <single-target-question
        v-else
        v-model="advancedMode"
        :loading="loading"
        :spec="single_spec"
        :show-advanced-mode-toggle="false"
        @change="singleChange($event)"
        @update-parameter="updateParameter($event)"
        @set-text="singleSetText($event)"
      />
    </template>
    <template v-if="!single_target_question && instantSelectionQuestion">
      <div class="flex flex-col xl:flex-row gap-2">
        <single-target-instant-selection-question
          v-for="specItem in spec.filter(x => x.instantSelectionOptionItemId && x.instantSelectionOptionItemId !== '00000000-0000-0000-0000-000000000000')"
          :key="specItem.id"
          class="flex-1"
          :loading="loading"
          :spec="specItem"
          @change="multiInstantChange($event)"
        />
      </div>
    </template>
    <template v-if="!single_target_question && !instantSelectionQuestion">
      <single-target-question
        v-if="apply_to_all_mode"
        v-model="advancedMode"
        :loading="loading"
        :spec="multi_as_single_spec"
        :show-advanced-mode-toggle="true"
        @set-text="singleSetText($event)"
        @change="singleChange($event)"
        @update-parameter="updateParameter($event)"
      />
      <multi-target-question
        v-else
        v-model="advancedMode"
        :loading="loading"
        :specs="spec"
        @change="multiChange($event)"
        @component-highlight="$emit('component-highlight', $event)"
      />
    </template>
  </div>
</template>

<script>
import SingleTargetInstantSelectionQuestion from '@/components/shared/designer/SpecificationGroup/SingleTargetInstantSelectionQuestion.vue';
import SingleTargetQuestion from '@/components/shared/designer/SpecificationGroup/SingleTargetQuestion.vue';
import MultiTargetQuestion from '@/components/shared/designer/SpecificationGroup/MultiTargetQuestion.vue';

export default {
  components: {
    'single-target-instant-selection-question': SingleTargetInstantSelectionQuestion,
    'single-target-question': SingleTargetQuestion,
    'multi-target-question': MultiTargetQuestion,
  },
  props: ['spec', 'loading'],
  data() {
    return {
      advancedMode: false,
    };
  },
  computed: {
    apply_to_all_mode() {
      return this.spec.length === 1 && !this.advancedMode;
    },
    instantSelectionQuestion() {
      return (
        this.spec[0].instantSelectionOptionItemId &&
        this.spec[0].instantSelectionOptionItemId !== '00000000-0000-0000-0000-000000000000'
      );
    },
    single_target_question() {
      return (
        this.spec.length === 1 &&
        this.spec[0].components.length <= 1 &&
        this.spec[0].members.length <= 1
      );
    },
    multi_target_question() {
      return (
        this.spec.length > 1 ||
        this.spec[0].components.length > 1 ||
        this.spec[0].members.length > 1
      );
    },
    single_spec() {
      if (this.spec.length === 1) {
        return this.spec[0];
      }

      throw new Error('Not a single spec question');
    },
    multi_as_single_spec() {
      if (!this.multi_target_question) {
        throw new Error('Not a multi spec capable question');
      }

      if (this.spec.length !== 1) {
        throw new Error('Question has split options, it needs to be combined first.');
      }

      return this.spec[0];
    },
  },
  watch: {
    advancedMode(set) {
      if (!set && this.spec.length > 1) {
        this.alertBox()
          .fire({
            title: 'Are you sure?',
            text: 'Your advanced selections will be reset',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$emit('change', {
                heading: this.spec[0].optionHeadingId,
                value: this.spec[0].options.filter((option) => option.isCurrentOption)[0].id,
                components: this.spec
                  .map((spec) => spec.components.map((component) => component.id))
                  .flat(1),
                members: this.spec.map((spec) => spec.members.map((member) => member.id)).flat(1),
              });
            } else {
              this.advancedMode = true;
            }
          });
      }
    },
  },
  mounted() {
    this.advancedMode = !this.apply_to_all_mode;
  },
  methods: {
    updateParameter({ heading, option, parameter, value }) {
      this.$emit('change', {
        heading,
        value: option,
        components: this.single_spec.components.map((component) => component.id),
        members: this.single_spec.members.map((member) => member.id),
        parameters: this.single_spec.parameters
          .map((param) => {
            if (
              param.componentId === parameter.componentId &&
              param.memberId === parameter.memberId &&
              param.index === parameter.index
            ) {
              return {
                ...param,
                value,
              };
            }

            return false;
          })
          .filter(Boolean),
      });
    },
    singleSetText({ heading, option, text }) {
      this.$emit('change', {
        heading,
        value: option,
        components: this.single_spec.components.map((component) => component.id),
        members: this.single_spec.members.map((member) => member.id),
        text,
      });
    },
    multiInstantChange({ heading, value }) {
      this.$emit('change', {
        heading,
        value,
        components: this.spec
          .map((spec) => spec.components.map((component) => component.id))
          .flat(),
        members: this.spec.map((spec) => spec.members.map((member) => member.id)).flat(),
      });
    },
    singleChange({ heading, value }) {
      this.$emit('change', {
        heading,
        value,
        components: this.single_spec.components.map((component) => component.id),
        members: this.single_spec.members.map((member) => member.id),
      });
    },
    multiChange({ heading, value, components, members, parameters }) {
      this.$emit('change', {
        heading,
        value,
        components,
        members,
        parameters,
      });
    },
  },
};
</script>
