<template>
  <touch-layout>
    <template #header_page_breadcrumb>
      <slot name="header_page_breadcrumb"></slot>
    </template>
    <template #header_page_title>
      <slot name="header_page_title"></slot>
    </template>
    <slot name="menu"></slot>
    <div class="flex flex-col flex-grow">
      <div class="flex flex-col gap-5 p-5 flex-grow bg-gray-200 w-full">
        <loading v-if="loading" :loading="true" class="bg-white p-5 border border-gray h-full"></loading>
        <div v-else-if="filteredCategories.length > 0" class="bg-white p-5 py-0 border border-gray overflow-y-auto" style="flex: 1 1 0; min-height: 200px;">
          <div class="flex flex-col gap-2" style="flex: 1 1 0">
            <table class="table-pricing table-auto border-collapse" style="min-width: 500px;">
              <thead class="sticky top-0 bg-white z-50 w-full">
                <tr>
                  <th></th>
                  <th class="px-4 py-5 text-left">Financial Description</th>
                  <th class="px-4 py-5 text-left">Mark Up</th>
                  <th class="px-4 py-5 text-left">No Mark Up To Price</th>
                  <th class="px-4 py-5 text-left">Wastage</th>
                </tr>
              </thead>
              <draggable v-model="categories" class="w-full" tag="tbody" handle=".handle">
                <tr v-for="category in filteredCategories" :key="category.id || category.description">
                  <td class="px-4 py-2">
                    <i class="cursor-move fa fa-align-justify handle"></i>
                  </td>
                  <td class="px-4 py-2">
                    {{ category.description }}
                  </td>
                  <td class="px-4 py-2">
                    <input
                      v-model.number="category.markup"
                      class="bg-white p-2 rounded-lg border"
                      type="number"
                      min="0"
                    />
                  </td>
                  <td class="px-4 py-2">
                    <div class="ml-auto flex items-center gap-5">
                      <span class="text-xs opacity-50">{{
                        category.noMarkupToPrice ? 'Enabled' : 'Disabled'
                      }}</span>
                      <div class="relative cursor-pointer" @click.stop="category.noMarkupToPrice = !category.noMarkupToPrice">
                        <div class="w-6 h-3 bg-gray-400 rounded-full shadow-inner"></div>
                        <div class="switch-toggle" :class="{ _active: category.noMarkupToPrice }"></div>
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-2">
                    <input
                      v-model.number="category.wastage"
                      class="bg-white p-2 rounded-lg border"
                      type="number"
                      min="0"
                    />
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
        <div v-else class="bg-white p-5 py-0 border border-gray flex justify-center items-center" style="flex: 1 1 0">
          <span>No Financial Categories Found.</span>
        </div>
      </div>
      <div class="sticky bottom-0 bg-white text-right p-3 border-t">
        <button
          class="btn-action btn-lg"
          :class="{
            'opacity-50 pointer-events-none bg-gray-500 border-gray-500':
              JSON.stringify(categories) === JSON.stringify(originalCategories),
          }"
          @click="updateFinancialCategories()"
        >
          Save Changes
        </button>
      </div>
    </div>
  </touch-layout>
</template>

<script>

import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.categories) !== JSON.stringify(this.originalCategories)) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          title: 'There are unsaved changes',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Go Back',
          confirmButtonText: 'Continue',
        })
        .then((result) => {
          if (result.value) {
            next()
          }
        })
    } else {
      next()
    }
  },
  data() {
    return {
      categories: [],
      originalCategories: [],
      loading: true,
      isInvalid: false,
      newCategory: {
        description: '',
        markup: '',
        noMarkupToPrice: false,
        wastage: '',
      }
    };
  },
  computed: {
    filteredCategories() {
      return this.categories.filter(x => !x.delete)
    }
  },
  async created() {
    await this.getFinancialCategories()
  },
  methods: {
    async getFinancialCategories() {
      this.categories = await window.touch.GetFinancialCategories()
      this.originalCategories = JSON.parse(JSON.stringify(this.categories));
      this.loading = false
    },
    async updateFinancialCategories() {
      this.loading = true
      const changedItems = this.getChanged()
      await window.touch.UpdateFinancialCategories(changedItems)
      await this.getFinancialCategories()
      this.loading = false
      this.isInvalid = false
    },
    getChanged() {
      let changedCategories = [];
      if (JSON.stringify(this.categories) !== JSON.stringify(this.originalCategories)) {
        for (let index = 0; index < this.categories.length; index += 1) {
          const category = this.categories[index]
          category.index = index
          const originalCategory = this.originalCategories.find(x => x.id === category.id)
          if (JSON.stringify(category) !== JSON.stringify(originalCategory) || !originalCategory) {
            changedCategories.push({
              Id: category.id,
              Description: category.description,
              ListIndex: index,
              Markup: parseInt(category.markup, 10),
              NoMarkupToPrice: category.noMarkupToPrice,
              Wastage: parseInt(category.wastage, 10),
              Delete: category.delete
            });
          }
        }
      }
      return changedCategories
    },
    addCategory() {
      if (this.newCategory.description === '' || this.newCategory.markup === '' || this.newCategory.wastage === '') {
        this.isInvalid = true
      } else {
        this.categories.push(JSON.parse(JSON.stringify(this.newCategory)))
        this.newCategory = {
          description: '',
          markup: '',
          noMarkupToPrice: false,
          wastage: '',
          listIndex: this.categories.length - 1
        }
      }
    },
    deleteCategory(category) {
      this.alertBox()
        .fire({
          title: 'Are you sure?',
          html: `Are you sure you want to delete <u><strong>${category.description}?</strong></u>`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirm',
        })
        .then((result) => {
          if (result.value) {
            if (category.id) {
              const index = this.categories.findIndex(x => x.id === category.id)
              this.$set(this.categories[index], 'delete', true)
            } else {
              const index = this.categories.findIndex(x => x.id === category.description && !category.category)
              this.categories.splice(index, 1);
            }
          }
        });
    },
  },
};
</script>